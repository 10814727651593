/* eslint-disable no-unused-vars */
import { computed, ref, watch } from '@vue/composition-api'
import { debounce, pickBy, identity } from 'lodash'

import store from '@/store'
import api from '@/api'
import { getUserData } from '@/api/auth/utils'

import useToast from '@useToast'

export default function usePromotionAgHandle() {
  // Use toast
  const { toastError, toastSuccess } = useToast()

  // Refs
  const refPromotionsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { label: 'checkbox', key: 'checkbox' },
    { label: 'no', key: 'no' },
    { label: 'code', key: 'code' },
    { label: 'discount', key: 'discount' },
    { label: 'status', key: 'active' },
    // { label: 'name', key: 'name' },
    { label: 'promotionType', key: 'paidType' },
    { label: 'applyTime', key: 'startTime' },
    { label: 'flightTime', key: 'flightStartTime' },
    { label: 'agencies', key: 'agencies' },
    { label: 'flightTrips', key: 'flightTrips' },
    { label: 'flightTypes', key: 'flightTypes' },
    { label: 'minimumPassenger', key: 'minimumPassenger' },
    { label: 'quota', key: 'quota' },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },
    { label: 'action', key: 'action' },
  ]

  const sizePerPage = ref(10)
  const totalPromotions = ref(0)
  const currentPage = ref(1)

  // Loading
  const loading = ref(null)

  // Sort
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  // Filter
  const typeFilter = ref('applyDate')
  const startDate = ref(null)
  const endDate = ref(null)
  const airline = ref(null)
  const code = ref(null)
  const name = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPromotionsListTable.value
      ? refPromotionsListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPromotions.value,
    }
  })

  // Empty filter
  const isEmptyFilter = computed(
    () => !!(
      typeFilter.value === null
        && startDate.value === null
        && endDate.value === null
    ),
  )

  const refetchData = () => {
    refPromotionsListTable.value.refresh()
  }

  const clearFilter = () => {
    typeFilter.value = 'applyDate'
    startDate.value = null
    endDate.value = null
    airline.value = null
    code.value = null
    name.value = null

    refetchData()
  }

  watch([currentPage, sizePerPage, sortBy, isSortDirDesc], () => {
    refetchData()
  })

  const fetchPromotionsByFilter = () => new Promise((resolve, reject) => {
    loading.value = true

    const time = typeFilter.value === 'applyDate'
      ? {
        fromTime: startDate.value,
        toTime: endDate.value,
      }
      : {
        flightFromTime: startDate.value,
        flightToTime: endDate.value,
      }

    const payload = {
      ...time,
      airline: airline.value,
      name: name.value,
      code: code.value,
      size: sizePerPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDirection: isSortDirDesc.value ? -1 : 1,
    }

    store
      .dispatch('app-promotionAg/fetchPromotions', payload)
      .then(response => {
        totalPromotions.value = response.data.total
        resolve(response.data.items)
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAg.fetchPromotionError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  // fetch Agencies
  const agenciesManagerList = ref([])
  const agenciesManagerListDefault = ref([])

  const fetchAgenciesManagerByFilter = async (search = '') => {
    loading.value = true
    agenciesManagerList.value = []
    try {
      const { data } = await api.agencies.fetchAgencies({
        isActive: true,
        searchText: search,
      })
      if (data && !search) {
        agenciesManagerListDefault.value = data.items
      }
      const agency = getUserData().employeeData.agency
      agenciesManagerList.value = data.items.filter(
        item => item.agencyCode !== agency.agencyCode,
      )
      // agenciesManagerList.value.unshift('All agencies')// { agencyName: 'ALL', agencyCode: null }
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const openAgenciesManager = () => {
    if (agenciesManagerListDefault.value.length === 0) {
      fetchAgenciesManagerByFilter()
    } else {
      agenciesManagerList.value = agenciesManagerListDefault.value
    }
  }

  const searchAgenciesManager = debounce(search => {
    if (search) fetchAgenciesManagerByFilter(search)
  }, 300)

  const modifyPayloadPromotion = promotion => {
    const payload = {
      ...pickBy(promotion, identity),
      ...(
        promotion.discountType === '%'
          ? { discountAmount: 0, discountPercent: promotion.discountPercent }
          : { discountAmount: promotion.discountAmount, discountPercent: 0 }
      ),
      flightTrips: promotion.flightTrips && promotion.flightTrips?.length ? promotion.flightTrips.map(item => item.trim()) : undefined,
      agencies: promotion.agencies && promotion.agencies?.length ? promotion.agencies : undefined,
      flightType: promotion.flightType || undefined,
      codeshares: promotion.codeshares && promotion.codeshares?.length ? promotion.codeshares : undefined,
      airline: promotion.airline !== 'all' && promotion.airline?.length ? promotion.airline : undefined,
      source: promotion.source !== 'all' && promotion.source?.length ? promotion.source : undefined,
      fareBasisCode: promotion.fareBasisCode && promotion.fareBasisCode?.length ? promotion.fareBasisCode : undefined,
      bookingClass: promotion.bookingClass && promotion.bookingClass?.length ? promotion.bookingClass : undefined,
      groupClass: promotion.groupClass && promotion.groupClass?.length ? promotion.groupClass : undefined,
      countryArrivals: promotion.countryArrivals && promotion.countryArrivals?.length ? promotion.countryArrivals : undefined,
      countryDepartures: promotion.countryDepartures && promotion.countryDepartures?.length ? promotion.countryDepartures : undefined,
      countryTrips: promotion.countryTrips && promotion.countryTrips?.length ? promotion.countryTrips : undefined,
      excludedAgencies: promotion.excludedAgencies && promotion.excludedAgencies?.length ? promotion.excludedAgencies : undefined,
      flightDesignators: promotion.flightDesignators && promotion.flightDesignators?.length ? promotion.flightDesignators : undefined,
      operator: promotion.operator && promotion.operator?.length ? promotion.operator : undefined,
      startTime: promotion.startTime ? `${promotion.startTime.slice(0, 10)}T00:00:00Z` : undefined,
      endTime: promotion.endTime ? `${promotion.endTime.slice(0, 10)}T23:59:59Z` : undefined,
      flightStartTime: promotion.flightStartTime ? `${promotion.flightStartTime.slice(0, 10)}T00:00:00Z` : undefined,
      flightEndTime: promotion.flightEndTime ? `${promotion.flightEndTime.slice(0, 10)}T23:59:59Z` : undefined,
    }
    if (!payload.flightStartTime) {
      delete payload.flightStartTime
    }
    if (!payload.flightEndTime) {
      delete payload.flightEndTime
    }

    return payload
  }

  const addPromotion = promotion => new Promise((resolve, reject) => {
    const payload = modifyPayloadPromotion(promotion)
    store
      .dispatch('app-promotionAg/createPromotion', payload)
      .then(response => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.promotionAg.createPromotionSuccess',
        })
        resolve(response)
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAg.createPromotionError',
        })
        reject(error)
      })
  })

  const updatePromotion = (id, promotion) => new Promise((resolve, reject) => {
    if (Object.keys(promotion).length === 0) { resolve() }
    const payload = modifyPayloadPromotion(promotion)

    store
      .dispatch('app-promotionAg/updatePromotion', {
        id,
        ...payload,
      })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.promotionAg.updatePromotionSuccess',
        })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAg.updatePromotionError',
        })
      })
  })

  const updateActive = (id, active) => {
    store
      .dispatch('app-promotionAg/updatePromotion', { id, active })
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.promotionAg.updatePromotionSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAg.updatePromotionError',
        })
      })
  }

  const deletePromotion = id => {
    store
      .dispatch('app-promotionAg/deletePromotion', { id })
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.promotionAg.deletePromotionSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAg.deletePromotionError',
        })
      })
  }

  return {
    fetchPromotionsByFilter,
    addPromotion,
    updatePromotion,
    updateActive,
    deletePromotion,

    agenciesManagerList,
    openAgenciesManager,
    searchAgenciesManager,

    tableColumns,
    sizePerPage,
    currentPage,
    totalPromotions,
    dataMeta,

    sortBy,
    isSortDirDesc,

    // Refs
    refPromotionsListTable,

    refetchData,
    clearFilter,

    // Extra Filters
    typeFilter,
    startDate,
    endDate,
    airline,
    code,
    name,

    // Loading
    loading,

    // Empty filter
    isEmptyFilter,
  }
}
