import { apiPromotionAirline } from '@/api/'

export default {
  namespaced: true,
  // state: {},
  // getters: {},
  // mutations: {},
  actions: {
    fetchPromotionAirline(ctx, params) {
      return apiPromotionAirline.fetchPromotionAirline(params)
    },
    createPromotionAirline(ctx, body) {
      return apiPromotionAirline.createPromotionAirline(body)
    },
    getPromotionAirlineById(ctx, { id }) {
      return apiPromotionAirline.getPromotionAirlineById(id)
    },
    updatePromotionAirline(ctx, body) {
      return apiPromotionAirline.updatePromotionAirline(body)
    },
    deletePromotionAirline(ctx, { id }) {
      return apiPromotionAirline.deletePromotionAirline({ id })
    },
  },
}
