// eslint-disable-next-line no-unused-vars
import { apiPromotion } from '@/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPromotions(ctx, params) {
      return apiPromotion.fetchPromotions(params)
    },
    createPromotion(ctx, body) {
      return apiPromotion.createPromotion(body)
    },
    getPromotionById(ctx, { id }) {
      return apiPromotion.getPromotionById(id)
    },
    updatePromotion(ctx, body) {
      return apiPromotion.updatePromotion(body)
    },
    deletePromotion(ctx, { id }) {
      return apiPromotion.deletePromotion({ id })
    },
  },
}
