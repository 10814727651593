/* eslint-disable no-unused-vars */
import { computed, ref, watch } from '@vue/composition-api'
import { pickBy, identity } from 'lodash'

import store from '@/store'

import useToast from '@useToast'

export default function usePromotionAirlineHandle() {
  // Use toast
  const { toastError, toastSuccess } = useToast()

  // Refs
  const refPromotionsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { label: 'checkbox', key: 'checkbox' },
    { label: 'no', key: 'no' },
    { label: 'code', key: 'code' },
    { label: 'airline', key: 'airline' },
    { label: 'status', key: 'active' },
    { label: 'applyTime', key: 'startTime' },
    { label: 'flightTime', key: 'flightStartTime' },
    { label: 'minimumPassenger', key: 'minimumPassenger' },
    { label: 'priority', key: 'priority' },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },
    { label: 'action', key: 'action' },
  ]

  const sizePerPage = ref(10)
  const totalPromotions = ref(0)
  const currentPage = ref(1)

  // Loading
  const loading = ref(null)

  // Sort
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  // Filter
  const typeFilter = ref('applyDate')
  const startDate = ref(null)
  const endDate = ref(null)
  const airline = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPromotionsListTable.value
      ? refPromotionsListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPromotions.value,
    }
  })

  // Empty filter
  const isEmptyFilter = computed(
    () => !!(
      typeFilter === null
        && startDate.value === null
        && endDate.value === null
    ),
  )

  const refetchData = () => {
    refPromotionsListTable.value.refresh()
  }

  const clearFilter = () => {
    typeFilter.value = 'applyDate'
    startDate.value = null
    endDate.value = null
    airline.value = null

    refetchData()
  }

  watch([currentPage, sizePerPage, sortBy, isSortDirDesc], () => {
    refetchData()
  })

  // ANCHOR fetchPromotionAirline
  const fetchPromotionAirline = () => new Promise((resolve, reject) => {
    loading.value = true

    const time = typeFilter.value === 'applyDate'
      ? {
        fromTime: startDate.value,
        toTime: endDate.value,
      }
      : {
        flightFromTime: startDate.value,
        flightToTime: endDate.value,
      }

    const payload = {
      ...time,
      airline: airline.value,
      size: sizePerPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDirection: isSortDirDesc.value ? -1 : 1,
    }

    store
      .dispatch('app-promotionAirline/fetchPromotionAirline', payload)
      .then(response => {
        totalPromotions.value = response.data.total
        resolve(response.data.items)
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAg.fetchPromotionError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  // ANCHOR createPromotionAirline
  const createPromotionAirline = promotion => new Promise((resolve, reject) => {
    const payload = {
      ...pickBy(promotion, identity),
      ...(promotion.discountType === '%'
        ? { discountAmount: 0, discountPercent: promotion.discount }
        : { discountAmount: promotion.discount, discountPercent: 0 }),
    }
    store
      .dispatch('app-promotionAirline/createPromotionAirline', payload)
      .then(response => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.promotionAg.createPromotionSuccess',
        })
        resolve(response)
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAg.createPromotionError',
        })
        reject(error)
      })
  })

  // ANCHOR updatePromotionAirline
  const updatePromotionAirline = (promotionData, promotion) => new Promise((resolve, reject) => {
    const payload = {
      ...pickBy(promotion, identity),
    }

    store
      .dispatch('app-promotionAirline/updatePromotionAirline', {
        id: promotionData.id,
        ...payload,
      })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.promotionAg.updatePromotionSuccess',
        })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAg.updatePromotionError',
        })
      })
  })

  const updateActive = (id, active) => {
    store
      .dispatch('app-promotionAirline/updatePromotionAirline', { id, active })
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.promotionAirline.updatePromotionSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAirline.updatePromotionError',
        })
      })
  }

  // ANCHOR deletePromotionAirline
  const deletePromotionAirline = id => {
    store
      .dispatch('app-promotionAirline/deletePromotionAirline', { id })
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.promotionAg.deletePromotionSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.promotionAg.deletePromotionError',
        })
      })
  }

  return {
    fetchPromotionAirline,
    createPromotionAirline,
    updatePromotionAirline,
    updateActive,
    deletePromotionAirline,

    tableColumns,
    sizePerPage,
    currentPage,
    totalPromotions,
    dataMeta,

    sortBy,
    isSortDirDesc,

    // Refs
    refPromotionsListTable,

    refetchData,
    clearFilter,

    // Extra Filters
    typeFilter,
    startDate,
    endDate,
    airline,

    // Loading
    loading,

    // Empty filter
    isEmptyFilter,
  }
}
