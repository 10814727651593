import { apiPromotionUser } from '@/api/'

export default {
  namespaced: true,
  // state: {},
  // getters: {},
  // mutations: {},
  actions: {
    fetchPromotionUser(ctx, params) {
      return apiPromotionUser.fetchPromotionUser(params)
    },
    createPromotionUser(ctx, body) {
      return apiPromotionUser.createPromotionUser(body)
    },

    updatePromotionUser(ctx, body) {
      return apiPromotionUser.updatePromotionUser(body)
    },

    bulkUpdatePromotionUser(ctx, body) {
      return apiPromotionUser.bulkUpdatePromotionUser(body)
    },
    activePromotionUser(ctx, id) {
      return apiPromotionUser.activePromotionUser(id)
    },
    deactivePromotionUser(ctx, id) {
      return apiPromotionUser.deactivePromotionUser(id)
    },
  },
}
