/* eslint-disable no-unused-vars */
import { computed, ref, watch } from '@vue/composition-api'

import store from '@/store'

import useToast from '@useToast'

export default function usePromotionUserHandle() {
  // Use toast
  const { toastError, toastSuccess } = useToast()

  // Refs
  const refPromotionsListTable = ref()
  // Table Handlers
  const tableColumns = [
    { label: 'no', key: 'no' },
    { label: 'employee', key: 'employee' },
    { label: 'source', key: 'source' },
    { label: 'promoCode', key: 'promoCode' },
    { label: 'maxApply', key: 'maxApply' },
    { label: 'promoCodeApplied', key: 'promoCodeApplied' },
    { label: 'status', key: 'isActive' },
    { label: 'created', key: 'createdAt', sortable: true },
    { label: 'updated', key: 'updatedAt', sortable: true },
  ]

  const sizePerPage = ref(10)
  const totalPromotions = ref(0)
  const currentPage = ref(1)

  // Loading
  const loading = ref(null)

  // Sort
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  const dataMeta = computed(() => {
    const localItemsCount = refPromotionsListTable.value
      ? refPromotionsListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPromotions.value,
    }
  })

  const refetchData = () => {
    refPromotionsListTable.value.refresh()
  }

  const updateIndex = ref(0)

  watch([currentPage, sizePerPage, sortBy, isSortDirDesc, updateIndex], () => {
    refetchData()
  })

  // ANCHOR fetchPromotionUser
  const fetchPromotionUser = () => new Promise((resolve, reject) => {
    loading.value = true

    const payload = {
      size: sizePerPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
    }

    store
      .dispatch('app-promotionUser/fetchPromotionUser', payload)
      .then(response => {
        totalPromotions.value = response.data.total
        resolve(response.data.items)
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'messagesList.error',
          content: 'promotionUser.list.fetchError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  // ANCHOR createPromotionUser
  const createPromotionUser = payload => new Promise((resolve, reject) => {
    store
      .dispatch('app-promotionUser/createPromotionUser', payload)
      .then(response => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'promotionUser.create.createSuccesful',
        })
        resolve(response)
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: 'promotionUser.create.createError',
        })
        reject(error)
      })
  })

  // ANCHOR updatePromotionUser
  const updatePromotionUser = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-promotionUser/updatePromotionUser', {
        id: payload.id,
        payload,
      })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'promotionUser.detail.editSuccessful',
        })
      })
      .catch(error => {
        reject(error)
        console.error({ error })
        toastError({
          title: 'messagesList.error',
          content: 'promotionUser.detail.editError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const toggleStatus = (id, isActive) => {
    store
      .dispatch(`app-promotionUser/${isActive ? '' : 'de'}activePromotionUser`, id)
      .then(() => {
        refetchData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.agency.actived',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
  }

  return {
    tableColumns,
    sizePerPage,
    currentPage,
    totalPromotions,
    dataMeta,
    sortBy,
    isSortDirDesc,
    refPromotionsListTable,
    loading,
    fetchPromotionUser,
    createPromotionUser,
    updatePromotionUser,
    toggleStatus,
    refetchData,
  }
}
