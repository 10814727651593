import { ref, computed, watch } from '@vue/composition-api'

import store from '@/store'

export default function usePromotionAgAirlines() {
  const allVnAirlines = ['QH', 'VN', 'VJ', 'VU']
  const airlineFilterOptions = ref([])
  const airlineFilterOptionsWithAllAirlines = ref([])
  store.dispatch('globalConfig/getAvailableDistributorSource')
  const allSources = computed(() => store.getters['globalConfig/getAllDistributors'])
  const loadingSources = computed(() => !allSources.value.length)
  const sourceFilterOptionsWithAllSources = ref([])
  const hasConfig = computed(() => store.getters['globalConfig/isHasConfig'])

  function getAirlineNameByCode(code) {
    return store.getters['globalConfig/getAirlineNameByCode'](code)
  }

  function createAirlineOption(value) {
    const label = getAirlineNameByCode(value)

    if (label === value) {
      return {
        label,
        value,
      }
    }

    return {
      label: `${label} (${value})`,
      value,
    }
  }

  function createValidAirlineOption(value) {
    const label = getAirlineNameByCode(value)

    if (label === value) return null

    return {
      label: `${label} (${value})`,
      value,
    }
  }

  function setAirlineFilterOptions() {
    airlineFilterOptions.value = allVnAirlines.map(value => createAirlineOption(value))
  }

  // function setAirlineFilterOptionsWithAllAirlinesOption() {
  //   airlineFilterOptionsWithAllAirlines.value = [
  //     { label: 'allAirlines', value: null },
  //   ].concat(airlineFilterOptions.value)
  // }

  function setAirlineFilterOptionsWithAllAirlinesOption() {
    airlineFilterOptionsWithAllAirlines.value = airlineFilterOptions.value
  }

  // function setSourceFilterOptionsWithAllSourcesOption() {
  //   sourceFilterOptionsWithAllSources.value = [
  //     { label: 'allSources', value: null },
  //   ].concat(allSources.value.map(source => ({
  //     label: source, value: source,
  //   })))
  // }

  function setSourceFilterOptionsWithAllSourcesOption() {
    sourceFilterOptionsWithAllSources.value = allSources.value.map(source => (
      {
        label: source, value: source,
      }
    ))
  }

  // values initialization whether global config is available or not
  setAirlineFilterOptions()
  setAirlineFilterOptionsWithAllAirlinesOption()
  setSourceFilterOptionsWithAllSourcesOption()

  watch(
    () => [hasConfig.value, allSources],
    ([val]) => {
      if (val) {
        setAirlineFilterOptions()
        setAirlineFilterOptionsWithAllAirlinesOption()
        setSourceFilterOptionsWithAllSourcesOption()
      }
    },
    { immediate: true, deep: true },
  )

  return {
    allVnAirlines,
    airlineFilterOptions,
    airlineFilterOptionsWithAllAirlines,
    allSources,
    loadingSources,
    sourceFilterOptionsWithAllSources,
    createValidAirlineOption,
  }
}
